import { readonly, ref, useContext, useRoute, } from '@nuxtjs/composition-api';
import { RoutableInterface } from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import type { ComposableFunctionArgs, UseUrlResolverErrors, UseUrlResolverInterface } from '~/composables';

/**
 * Allows searching the resolver for current
 * route path (URL).
 *
 * See the {@link UseUrlResolverInterface} for a list of methods and values available in this composable.
 */
export function useUrlResolver(): UseUrlResolverInterface {
  const route = useRoute();
  const { error: nuxtError, app, $config } = useContext();
  const context = app.$vsf;
  const { path } = route.value;
  const loading = ref(false);
  const error = ref<UseUrlResolverErrors>({
    search: null,
  });

  const search = async (params?: ComposableFunctionArgs<{}>): Promise<RoutableInterface | Object | null> => {
    loading.value = true;
    let results: RoutableInterface | null = null;

    try {
      let clearUrl = path.replace(/(catalogue|produit)\//gi, '');
      Logger.debug('[Magento] Find information based on URL', { clearUrl });
      if (clearUrl === '/search' || clearUrl === '/promo') {
        return {
          relative_url: clearUrl,
          redirect_code: 0,
          type: 'CATEGORY'
        };
      } else {
        // re-add root slug
        const rootCategory = $config.rootCategory;
        clearUrl = '/' + rootCategory + clearUrl;
      }
      const { data } = await context.$magento.api.route(clearUrl, params?.customQuery ?? null);
      results = data?.route ?? null;

      if (!results) {
        return nuxtError({ statusCode: 404, message: app.i18n.t('Page not found') as string });
      }

      Logger.debug('[Result]:', { results });
    } catch (err) {
      error.value.search = err;

      Logger.error('useUrlResolver/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    path,
    search,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './UseUrlResolver';
export default useUrlResolver;
