import { readonly, ref, useContext, } from '@nuxtjs/composition-api'
import { Logger } from '~/helpers/logger'
import { ComposableFunctionArgs } from '~/composables';
import { ProductConditionQuery, ProductConditionQueryVariables } from '~/custom-api/api/productConditions/types';


export function useProductConditions() {
  const { app } = useContext();
  const conditions = ref([])
  const loading = ref(false);
  const error = ref(null)


  const fetchProductConditions = async (params: ComposableFunctionArgs<ProductConditionQueryVariables>): Promise<ProductConditionQuery[]> => {
    loading.value = true;

    try {
      Logger.debug('[Magento]: Get product conditions for products with code ', params);

      //@ts-ignore
      const { data } = await app.$vsf.$magento.api.productConditions(params);
      conditions.value = data.productsConditions;

      Logger.debug('[Product conditions Result]: ', conditions.value)
      error.value = null
    } catch (err) {
      error.value = err
      Logger.error('useProductConditions/getProductConditions', err)
    } finally {
      loading.value = false
    }

    return conditions.value
  }

  const getConditionBySku = (sku: string): ProductConditionQuery =>
    conditions.value?.find(productCondition => productCondition.sku = sku)


  return {
    fetchProductConditions,
    getConditionBySku,
    error: readonly(error),
    loading: readonly(loading)
  }
}


export default useProductConditions;
