






import { defineComponent, onMounted } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HTMLContent',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    onMounted(async () => {
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(props.content, 'text/html');
      if (doc && doc.getElementsByTagName('script') && doc.getElementsByTagName('script')[0]) {
        eval(doc.getElementsByTagName('script')[0].innerHTML)
      }
    });
    return {
      onMounted
    };
  },
});
