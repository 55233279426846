import {computed, readonly, Ref, ref, useContext} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { useCustomerStore } from '~/modules/customer/stores/customer';

const loading: Ref<boolean> = ref(false);

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<CART,
  CART_ITEM,
  PRODUCT> {
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const cartStore = useCartStore();
  const customerStore = useCustomerStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, realCart = false } = { customQuery: { cart: 'customCart' } }): Promise<void> => {
    Logger.debug('useCart.load');

    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    const tokenId = token ? token : '';

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, realCart, tokenId });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        if (!state.cart) {
          state.cart = {};
        }
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
     product, quantity, selectedOptionSku, optionsConfiguration, isCustom, customQuery, pricingUnit
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity, selectedOptionSku, optionsConfiguration, isCustom });

    try {
      loading.value = true;

      if (!cartStore.cart.id) {
        await load({ realCart: true });
      }

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      await setShopToCart();

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        selectedOptionSku,
        optionsConfiguration,
        isCustom,
        tokenId,
        customQuery,
        pricingUnit
      });

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
        state.cart.shop_code = customerStore.getCurrentShopCode;
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery = { removeItemFromCart: 'customRemoveCartItem' } }) => {
    Logger.debug('useCart.removeItem', { product });

    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    const tokenId = token ? token : '';

    if (!customQuery) {
      customQuery = { removeItemFromCart: 'customRemoveCartItem' };
    }

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        tokenId,
        customQuery,
      });

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'customUpdateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    const tokenId = token ? token : '';

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          tokenId,
          customQuery,
        });

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');
    let currentShop = null;
    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    const tokenId = token ? token : '';
    loading.value = true;
    const cartId = apiState.getCartId();

    // @ts-ignore @TODO add establishment to ApiClientMethods
    if (tokenId) {
      currentShop = await app.$vsf.$magento.api.getCurrentShop(tokenId);
      if (currentShop.data != undefined && currentShop.data.getCurrentShop != undefined && currentShop.data.getCurrentShop.current_shop != undefined) {
        if (cartId) {
          await app.$vsf.$magento.api.setCurrentShop(currentShop.data.getCurrentShop.current_shop, tokenId, cartId);
        }
      }
    }

    try {
      loading.value = true;
      if (cartId) {
        await handleCoupon(couponCode, customQuery);
      }
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const setShopToCart = async () => {
    const tokenId = app.$cookies.get('shop_token_id', { parseJSON: false }) ?? '';

    if (!tokenId) {
      return null;
    }

    let customerShop = customerStore.getCurrentShopCode;
    if (!customerShop) {
      const currentShop = await app.$vsf.$magento.api.getCurrentShop(tokenId)
      if (currentShop?.data?.getCurrentShop?.currentShop) {
        customerShop = currentShop?.data?.getCurrentShop?.currentShop;
        customerStore.setCurrentShopCode(customerShop);
      }
    }

    if (customerShop !== undefined && customerShop !== cartStore.cart?.shop_code) {
        const cartId = apiState.getCartId();
        if (cartId) {
          await app.$vsf.$magento.api.setCurrentShop(customerShop, tokenId, cartId);
        }
      }
    }

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';
