import { Exact, InputMaybe, Scalars } from '@vue-storefront/magento-api/lib/types/GraphQL';
import { CartItemInterface } from '@vue-storefront/magento-api';

export declare type ProductAttributeDataQueryVariables = Exact<{
  sku?: InputMaybe<Scalars['String']>;
}>;

export declare type ProductAttributeDataQuery = {
  attributeValues?:
    | {
    appearance?: string | null
    coverage?: string | null
    coverage_unit?: string | null
    distribution_brand?: Array<{
      asset_label: string;
      file_path: string;
    } | null | undefined>;
    dop?: string | null
    dop_declarationofperformance?: string | null
    dop_declarationofperformance2?: string | null
    dop_declarationofperformance3?: string | null
    selection_guide?: string | null
    drying_time?: string | null
    facets_approvals_certificates?: Array<{
      asset_label: string;
      file_path: string;
    } | null | undefined>;
    family_product_by_trade?: string | null
    gloss?: string | null
    key_feature_1?: string | null
    key_feature_2?: string | null
    key_feature_3?: string | null
    key_feature_4?: string | null
    long_description?: string | null
    other_product_specifications?: string | null
    paint_recommended_uses?: string | null
    paint_technology?: string | null;
    product_water_solvant?: Array<{
      asset_label: string;
      file_path: string;
    } | null | undefined>;
    technical_data_sheet_pdf?: string | null
    technical_data_sheet_pdf_2?: string | null
    roller_size?: string | null
    roller_appearance?: string | null
    roller_type?: string | null
    brush_type?: string | null
    material?: string | null
    water_solvant_based_paint?: string | null
    gfr_packing_article?: string | null
    gfr_packing_product?: string | null
    packing?: string | null
    items_included?: string | null
    warranty?: string | null
    power_supply?: string | null
    flow?: string | null
    pressure?: string | null
    tip_size?: string | null
    tank_capacity?: string | null
    dimensions?: string | null
    product_or_article_weight?: string | null
    safety_data_sheet_pdf?: string | null
    variation_parent_product?: string | null
    is_pre_painted?: string | null
    is_coating?: string | null
    is_sound_avantage?: string | null
    is_to_paint?: string | null
    is_pre_glued?: string | null
    type_of_glue?: string | null
    type_of_mesh?: string | null
    adhesive_application?: string | null
    roll_length?: string | null
    roll_width?: string | null
    grammage_m2?: string | null
    light_resistance?: string | null
    pattern_repeat?: string | null
    match_height?: string | null
    wallpaper_washability?: string | null
    wallpaper_removal?: string | null
    wet_room_compatible?: string | null
    transmission_sound_insulation?: string | null
    is_usage?: string | null
    m2_pack?: string | null
    flooring_length?: string | null
    flooring_width?: string | null
    flooring_thickness?: string | null
    underfloor_heating_compatible?: string | null
    floor_refreshing_compatible?: string | null
    strong_sunlight_compatible?: string | null
    cut_to_length?: string | null
    slip_resistance?: string | null
    antistatic?: string | null
    wear_layer_thickness?: string | null
    room_impact_sound?: string | null
    transmission_noise?: string | null
    url_agec?: string | null
    seo_bloc_product?: string | null
    link_video?: string | null
  }
    | null
    | undefined;
};


type PriceTax = { exclTax: number, inclTax: number };

export interface CartProductItemView {
  sku: string,
  image: {
    src: string,
    width: number,
    height: number,
  },
  prices: {
    type: string, /** Type de prix (REGULAR, PROMO, SPECIAL) */
    packagingPrice: PriceTax, /** Prix au produit */
    packagingRegular: PriceTax, /** Prix non remisé */
    unitLabel: string, /** Unité de vente (ex: L) */
    unitPrice: PriceTax, /** Prix à l'unité (ex: prix au L) */
    unitRegular: PriceTax, /** Prix à l'unité non remisé (ex: prix au L) */
    rowTotal: PriceTax, /** Prix total avec la quantité */
    discount: { label: string, percent: 0, amount: PriceTax } | null, /** Montant et type de la remise */
  },
  qty: number,
  title: string,
  deliveryStock: {
    isOk: boolean,
    label: string
  },
  storeStock: {
    isOk: boolean,
    label: string
  },
  items: CartItemInterface[],
  uid: string,
  product: CartItemInterface,
}

export enum ProductFlapType {
  Promo = 'promo',
  Remise = 'remise',
  Nouveau = 'nouveau',
  Destock = 'destock',
}
